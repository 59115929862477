<template>
  <v-container>
    <v-row justify="space-around">
      <v-col cols="12" md="6">
        <br> <br>
            <v-card
            class="mx-auto animate__animated animate__backInLeft"
            max-width="750"
            prepend-icon="../img/serves/logo.jpeg"
          >
            <template v-slot:title>
              <span class="font-weight-black" style=" font-size: 17px;">SERVICE WITH THE HIGH QUALITY PROFESSION</span>
            </template>
        
            <v-card-text class="bg-surface-light pt-4">
              The purpose of this civil method statement is to outline and describe in detail the procedure such as setting and lay-out plan on actual location, laying of tiles, mixing of mortar tile grout, final laying of tiles and all other related activity.
              <br><br>This flooring tile activity should be performed with strict compliance and implementation of project safety requirement, quality procedure and project specifications to complete the work both for floor and wall tiling works.
              <br><br>When materials are delivered, Check, examine marks, and the condition of materials and components . Tile sizes, thickness and colors are as grouts are as specified and are suitable for the conditions to which the tiling will be used.
              </v-card-text>
          </v-card>
         
        
      </v-col>

      <v-col cols="12" md="6">
     <v-card max-width="450" class="mx-auto animate__animated animate__backInRight">
        <v-img
        class="mt-3"
        elevation="6"
        height="390px"
          src="../img/serves/1.jpeg"
          width="450"
          cover
        ></v-img>
     
      </v-card>
       
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="12" md="6">
        <br> <br><br>

        <v-card
        class="mx-auto animate__animated animate__backInLeft"
        max-width="700"
      >
        <template v-slot:title>
          <span class="font-weight-black " style=" font-size: 17px;">QUALITY GUARANTEED</span>
        </template>
      
        <v-card-text class="bg-surface-light pt-4">
          High Quality Fixer Technical Service Team provides a premium porcelain tile system with a better design, easier installation, lower maintenance, and more color options.
            <br>We present a unique opportunity for you to coordinate interior and exterior spaces with high-quality that can be placed everywhere . We provide several advantages for our
             client to have more satisfaction and fulfilment of the outcome processed.
          </v-card-text>
      </v-card>
         
        
      </v-col>

      <v-col cols="12" md="6">
        <v-card max-width="450" class="mx-auto animate__animated animate__backInRight">
          
        <v-img
        class="mt-6"
        cover
  src="../img/serves/2.jpeg"
  width="450"
  height="450"
></v-img>
       </v-card>
   
       
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="12" md="6">
        <br> <br>

        <v-card
        class="mx-auto animate__animated animate__backInLeft"
        max-width="700"
      >
        <template v-slot:title>
          <span class="font-weight-black" style=" font-size: 17px;">TILE EFFECTS LIKE NO OTHER</span>
        </template>
      
        <v-card-text class="bg-surface-light pt-4">
      Our custom tile services ensure that your artistic vision becomes a 
      reality. With easy-to-use tools to make your own patterns and designs, getting the look you want has never been easier.
          </v-card-text>
      </v-card>
         
        
      </v-col>

      <v-col cols="12" md="6">
        <v-card max-width="450" class="mx-auto animate__animated animate__backInRight">
        <v-img
         class="mt-4"
          cover
          src="../img/serves/3.jpeg"
          width="450"
          ></v-img>
       
   </v-card>
       
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="12" md="6">
        <br> 

        <v-card
        class="mx-auto animate__animated animate__backInLeft"
        max-width="700"
      >
        <template v-slot:title>
          <span class="font-weight-black " style=" font-size: 17px;">LARGE FORMAT TILES</span>
        </template>
      
        <v-card-text class="bg-surface-light pt-4">
          Large-format tiles are growing in popularity and this can be attributed to the many advantages of using large format tiles rather than a more common-sized tile. Among the advantages of using large format tiles, the most common is the aesthetically pleasing and open look that they can give to any residential or commercial applications as well as the easier maintenance resulting from fewer grout joints. Using large format tiles can also add a dramatic effect to a wall or floor with the many variations of patterns that can be created.<br>Large format tiles are wall or floor tiles that exceed the average 120x240 and 120x280 sized tile. 
            <br>These tiles are available in square or rectangular shapes and can be as large as 160x320
          </v-card-text>
      </v-card>
         
        
      </v-col>
      
      <v-col cols="12" md="6">
        <v-card max-width="450" class="mx-auto animate__animated animate__backInRight">
        <v-img
          class="mt-6"
          cover
           src="../img/serves/4.jpeg"
          width="450"
        ></v-img>
      </v-card>
       </v-col>
      
    </v-row>
    <v-row justify="space-around">
      <v-col cols="12" md="6">
       
        <br> <br>  <br> <br>
        <v-card
  class="mx-auto animate__animated animate__backInLeft"
  max-width="700"
>
  <template v-slot:title>
    <span class="font-weight-black" style=" font-size: 17px;">TEAM PROFESIONALS</span>
  </template>

  <v-card-text class="bg-surface-light pt-4">
    A perfect tile installation requires a combination
       of knowledge, expertise, culture and skill and is something that only a highly trained professional can achieve. This means that choosing the right installation professional is just as important as selecting the perfect ceramic tile to be installed in one’s home.
    </v-card-text>
</v-card>
         
        
      </v-col>

      <v-col cols="12" md="6">
        <v-card max-width="450" class="mx-auto animate__animated animate__backInRight">
        <v-img
        class="mt-6"
        cover
           src="../img/serves/5.jpeg"
          width="450"
          height="450"
        ></v-img>
       
      </v-card>
       
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="12" md="6">
        <br> <br>


        <v-card
        class="mx-auto animate__animated animate__backInLeft"
        max-width="700"
      >
        <template v-slot:title>
          <span class="font-weight-black" style=" font-size: 17px;">CUSTOMER'S SATISFACTION</span>
        </template>
      
        <v-card-text class="bg-surface-light pt-4">
          We promise open communication and financial transparency 
            to accommodate every project and budget. We offer an end-to-end client experience that includes seamless communication, budgeting, staffing, on site organization, and solid quality handiwork every time.
          </v-card-text>
      </v-card>
         
        
      </v-col>

      <v-col cols="12" md="6">
        <v-card max-width="450" class="mx-auto animate__animated animate__backInRight">

        <v-img
        class="mt-6"
           src="../img/serves/6.jpeg"
          width="450"
          cover
        ></v-img>
       
</v-card>
       
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {

}
</script>

<style>

</style>