<template>
  <v-container  class="">
    <v-row >
      <v-col
        v-for="n in 225"
        :key="n"
      
        class="d-flex child-flex animate__animated animate__backInDown"
        cols="4"
      >
        <v-img
       
          :lazy-src="`../img/gallry/img${n}.jpg`"
          :src="`../img/gallry/img${n}.jpg`"
          aspect-ratio="1"
          class="bg-grey-lighten-2"
          cover
        >
          <template v-slot:placeholder>
            <v-row
              align="center"
              class="fill-height ma-0"
              justify="center"
            >
              <v-progress-circular
                color="grey-lighten-5"
                indeterminate
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
</v-container>
</template>



<style>

</style>