<template>

    <v-container  class="">

      <v-carousel class="animate__animated animate__backInDown" elevation="24" cycle hide-delimiters>
        <v-carousel-item
          v-for="(item,i) in items"
          :key="i"
          :src="item.src"
          cover
        ></v-carousel-item>
      </v-carousel>
      <br>  <br>
      <v-row justify="space-around">
        <v-col cols="12" md="6">
          <br>
      <v-card
      class="mx-auto animate__animated animate__backInLeft mt-8"
      max-width="700"
   
    >
      <!-- <template v-slot:title>
        <span class="font-weight-black"></span>
      </template> -->
  
      <v-card-text class="bg-surface-light pt-4 ">
        Our company providing quality flooring and fit-out services since the year business began.
and we pride ourselves on creating an qutstanding experince for each and every one of our  clients we specializa large format of tiles.
        <br><br>
        
our team is knowledgeable for installing all types of floors that can suits your budget 
we are aiming customer's satisfaction as being our foundation for becoming a successful group 
of company in the whole unites Arab Emirates
        <br><br>
        </v-card-text>
    </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card
      class="mx-auto animate__animated animate__backInRight"
      max-width="400"
   
    >
      <v-img
      class=""
      elevation="6"
      height="390px"
        src="../img/temawork.jpeg"
        width="450"
        cover
      ></v-img>
      </v-card>
      </v-col>
    </v-row>
</v-container>  
</template>
<script>
  export default {
    data () {
      return {
        items: [
          {
            src: '../img/gallry/img121.jpg',
          },
          {
            src: '../img/gallry/img137.jpg',
          },
          {
            src: '../img/gallry/img178.jpg',
          },
          {
            src: '../img/gallry/img195.jpg',
          },
        ],
      }
    },
  }
</script>