<template>
  <v-container>
    <v-row justify="space-around">
      <v-col cols="12" md="6">
        <v-card
        class="mx-auto animated bounceInDown"
        max-width="500"
      >
        <v-card-item class="">
          <v-card-title>
            <span class="text-h5">Contact Us</span>
          </v-card-title>
    
          <!-- <template v-slot:append>
            <v-defaults-provider
              :defaults="{
                VBtn: {
                  variant: 'text',
                  density: 'comfortable',
                }
              }"
            >
              <v-btn icon="mdi-chevron-left"></v-btn>
    
              <v-btn icon="mdi-pencil"></v-btn>
    
              <v-btn icon="mdi-dots-vertical"></v-btn>
            </v-defaults-provider>
          </template> -->
        </v-card-item>
    
        <v-list>
          <!-- <v-list-item
            append-icon="mdi-message-text"
            prepend-icon="mdi-phone"
            title="(650) 555-1234"
          ></v-list-item> -->
    
          <v-divider inset></v-divider>
    
          <v-list-item
            append-icon="mdi-message-text"
            prepend-icon="mdi-phone"
            title="+971508386767
"
          ></v-list-item>
    
          <v-divider inset></v-divider>
    
          <v-list-item
            prepend-icon="mdi-email"
            title="info@highqualityfixer.com
"
          ></v-list-item>
    
          <v-divider inset></v-divider>
    
          <v-list-item
            prepend-icon="mdi-map-marker"
            title="Orlando, FL 79938"
          ></v-list-item>
        </v-list>
<!--     
        <v-img
          height="200"
          src="https://picsum.photos/700?image=996"
          cover
        ></v-img> -->
      </v-card>
      </v-col>

      <v-col cols="12" md="6">
    <v-card
      class="mx-auto"
      width="450">

    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.601136235049!2d55.350994099999994!3d25.2839997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5df7d94523b1%3A0x5d3352b43ecb765e!2sHigh%20Quality%20Fixers!5e0!3m2!1sen!2seg!4v1721211931119!5m2!1sen!2seg" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
 
  </v-card>
      </v-col>
    
    </v-row>
  </v-container>
</template>
