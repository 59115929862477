<template>

  <v-layout>
  <v-app-bar
  dense
  clipped-left
  color="#0c0a2f"
  app
  :extended="extended"
  dark
>
  <v-app-bar-nav-icon
    @click.stop="drawer= !drawer"
    class="btn-fix"  ></v-app-bar-nav-icon>
 <v-toolbar-side-icon>
  <v-img class="mt-2" src="../img/logo.png" height="110px" width="125px"> 
  </v-img>
  
 </v-toolbar-side-icon>
  <v-toolbar-title>
    
    HIgh Quality Fixer
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn target="_blank" href="https://www.instagram.com/highqualityfixertech.service" icon>
      <v-icon>mdi-instagram</v-icon>
    </v-btn>

    <v-btn target="_blank" href="https://www.facebook.com/profile.php?id=100086205110181" icon>
      <v-icon>mdi-facebook</v-icon>
    </v-btn>
  </v-app-bar>
  <v-navigation-drawer
  width=200
  clipped
  v-model="drawer"
  
  app
>
  <!-- <v-list-item class="px-2">
    <v-list-item-avatar>
      <v-img src="https://gravatar.com/avatar/368a41425918f68f123aec64a386e223?s=80&d=https://static.codepen.io/assets/avatars/user-avatar-80x80-bdcd44a3bfb9a5fd01eb8b86f9e033fa1a9897c3a15b33adfc2649a002dab1b6.png"></v-img>
    </v-list-item-avatar>

    <v-list-item-title>Jim R</v-list-item-title>

    <v-btn
      icon
      @click.stop="mini = !mini"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
  </v-list-item> -->

  <v-divider></v-divider>

  <v-list dense>
    <v-list-item
      v-for="item in items"
      :key="item.title"
      link
    >            
    <v-list-item-icon>

      <v-list-item>
        <v-btn flat :to="item.to">{{ item.title }}</v-btn>
      </v-list-item>
      </v-list-item-icon>
<!-- 
      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content> -->
    </v-list-item>
  </v-list>
</v-navigation-drawer>
</v-layout>
<br><br>


  </template>
  
  <script>
    export default {
      data: () => ({
        mini: false,
        drawer: false,
      items: [
        { title: 'Home',to:"/", icon: 'mdi-home-city', },
        { title: 'Service', icon: 'mdi-account',to:"Service" },
        {title:"Gallery",to:"gallery"},
        { title: 'Contact Us', icon: 'mdi-account-group-outline',to:"contact" ,},
      ],
     
      }),
   
    
    }
    
  </script>
  
  <style>
  
  </style>
  