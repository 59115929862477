<template>
  <v-container class="">
    <v-card
    class="mx-auto animate__backInLeft"
    max-width="900"
  >
    <template v-slot:title>
      <span class="font-weight-black">VISION AND MISSION</span>
    </template>

    <v-card-text class="bg-surface-light pt-4">
      High Quality Fixer Technical Service enables construction professionals, contractors, merchants, oversight bodies, architects and their organizations to plan major construction projects and smaller tasks with confidence. In order to make this possible, we combine knowledge, experience and a hands-on approach with a range of high quality products. We prefer to build long-term partnerships rather than focus on short-term profits. We regularly subject our products and systems to practical tests, while paying particular attention to the impact of our activities on the environment and society.
      Several parties are involved in the construction process. We provide high quality products with excellent application properties and offer durable systems. We also prepare specially tailored advice and provide excellent services, while placing the emphasis on customer satisfaction. We are one of the best company here in Dubai, Guaranteed with the best team work on the field area.
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>