import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../components/IndexPage.vue'
import AboutUs from '../components/AboutUs.vue'
import ServicePage from '@/components/ServicePage.vue'
import ContactPage from '@/components/ContactPage.vue'
import Gallery from '@/components/GalleryP.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component:AboutUs
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
   // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/service',
    name: 'servicePage',
    component: ServicePage
  },
  {
    path: '/contact',
    name: 'contactPage',
    component: ContactPage
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: Gallery
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
