<template>
 
    <tool-bar>
      
    </tool-bar>
    <br>
    <v-layout>
    <v-main >
      
      <router-view/>
    
    </v-main>
  </v-layout>
 
  <!-- <router-view/> -->
</template>
<script>
// @ is an alias to /src
import ToolBar from '@/components/layouts/ToolBar.vue'

export default {
  name: 'HomeView',
  components: { ToolBar}
}
</script>

